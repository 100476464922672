const initialState = {
    token: {
        nickname: '-'
        , acl: []
    }
};

const api = (state = initialState, action) => {
    switch (action.type) {
        case 'SEND_MESSAGE':
            return {...state, room: action.payload};
        case 'USERS_COUNT':
            return {...state, online: action.payload};
        case 'UPDATE_TOKEN_INFO':
            return {...state, token: action.payload};
        default:
            return state;
    }
}

export default api;
