import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'isomorphic-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import './scss/index.scss';
import log from "loglevel";

import {App} from './App'
import configureStore from './store/configureStore';

import reportWebVitals from './reportWebVitals';

log.setLevel('DEBUG');
const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
