export const sendMessage = (room, message) => ({
    type: 'SEND_MESSAGE',
    payload: {room, message}
});
/*
Обновить кол-во пользователей на сервере - залогинившихся и гостей
 */
export const updateUsersCount = (data) => ({
    type: 'USERS_COUNT',
    payload: {...data}
});

export const updateTokenInfo = (info) => ({
    type: 'UPDATE_TOKEN_INFO',
    payload: {...info}
});
