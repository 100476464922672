import React, {createContext, useCallback, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import ReconnectingWebSocket from 'reconnectingwebsocket'
import log from 'loglevel'
import {updateTokenInfo, updateUsersCount} from "../../actions/WebConnectAction"

export const WebConnectContext = createContext(null)

const getNullToken = () => ({nickname: '??', acl: []})

const loadToken = (name) => {
    const t = localStorage.getItem(name)
    return t !== null ? JSON.parse(t) : getNullToken()
}

const useWebConnect = ({children}) => {
    const dispatch = useDispatch();
    const config = useSelector(state => state.config);
    const [url, setUrl] = useState({
        wss: `wss://${window.location.hostname}/ws/`,
        api: process.env.REACT_APP_COMMON_URL + '/api/c',
        auth: process.env.REACT_APP_COMMON_URL + '/auth',
    })
    const token = useRef(loadToken('token'));
    const [websocket, setWebsocket] = useState();
    const [delayed,] = useState(new Map());

    const onWebsocketSend = useCallback((websocket, message, callback, ctx) => {
        log.debug('[WS#] Send', message);
        if (callback !== undefined && ctx !== undefined) {
            delayed[ctx] = callback;
        }
        websocket.send(JSON.stringify({...message, cb: ctx}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCallbackProcessing = useCallback((websocket, md) => {
        const removeTokenInfo = () => {
            localStorage.removeItem('token');
            const tk = getNullToken();
//            updateToken(tk);
            dispatch(updateTokenInfo({nickname: tk.nickname, community: tk.community}));
        }
        const setTokenInfo = (token) => {
            localStorage.setItem('token', JSON.stringify(token));
            //          updateToken(token);
            dispatch(updateTokenInfo({nickname: token.nickname, community: token.community}));
        };

        const {t, m = {}, cb} = md;
        switch (t) {
            case 'atz':
                switch (m.a) {
                    case 'enter':
                        if (m.u !== 0) {
                            dispatch(updateTokenInfo({nickname: token.nickname, community: token.community}));
                        } else if (m.u === 0 && token.refresh_token !== undefined) {
                            onWebsocketSend(websocket, {t: 'atz', m: {a: 'refresh', t: token.refresh_token}});
                        } else {
                            removeTokenInfo();
                        }
                        break;
                    case 'refresh':
                        if (m.u !== 0) {
                            setTokenInfo(m.tk);
                        } else {
                            removeTokenInfo();
                        }
                        break;
                    case 'login':
                        if (m.u !== 0) {
                            setTokenInfo(m.tk);
                        }
                        break;
                    case 'logout':
                        removeTokenInfo();
                        break;
                    default:
                        log.warn(`Tag ${t}-${m.a} not found`, m);
                }
                break;
            case 'inf':
                dispatch(updateUsersCount(m))
                break;
            default:
                log.warn(`Tag ${t} not found`, m);
        }
        if (cb !== undefined) {
            const f = delayed[cb];
            f && f(md);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, token]);

    useEffect(() => {
        if (config.wss?.enable) {
            if ((config.wss?.dsn ?? '') !== '') {
                setUrl(prev => ({...prev, wss: config.wss?.dsn}));
            }

            if (websocket === undefined) {
                const ws = new ReconnectingWebSocket(url.wss, null, {debug: true, reconnectInterval: 3000});
                ws.onopen = () => {
                    log.debug(`[WS#] Connected to ${url.wss}`);

                    onWebsocketSend(ws, {
                        t: 'atz'
                        , m: {
                            a: 'enter'
                            , t: token.access_token
                        }
                    });
                }
                ws.onmessage = (message) => {
                    log.debug('[WS#] Receive0', message.data);
                    onCallbackProcessing(ws, JSON.parse(message.data));
                }
                ws.onclose = () => {
                    log.debug('[WS#] Disconnected');
                }
                setWebsocket(ws);
            } else {
                websocket.onmessage = (message) => {
                    log.debug('[WS#] Receive', message.data);
                    onCallbackProcessing(websocket, JSON.parse(message.data));
                }
            }
        }
        if ((config.api?.dsn ?? '') !== '') {
            setUrl(prev => ({...prev, api: config.api?.dsn}));
        }
        if ((config.auth?.dsn ?? '') !== '') {
            setUrl(prev => ({...prev, auth: config.auth?.dsn}));
        }
        dispatch(updateTokenInfo({nickname: token.current.nickname, acl: token.current.acl}))
    }, [config, dispatch, onCallbackProcessing, onWebsocketSend, url.api, url.auth, url.wss, websocket]);

    const isUserAuthenticated = () => {
        return token.current?.acl?.length > 0
    }

    const isRefreshTokenExpired = () => {
        if (token.current?.refresh_expire === undefined) {
            return true;
        }

        const now = new Date(), re = new Date(token.current.refresh_expire);
        return re.getTime() <= now.getTime();
    }

    const getNickname = () => token.current.nickname;

    const ws_send = (message, callback, ctx) => {
        onWebsocketSend(websocket, message, callback, ctx);
    }

    const logout = (callback, ctx) => {
        onWebsocketSend(websocket, {
            t: 'atz'
            , m: {
                a: 'logout'
                , t: token.refresh_token
            }
        }, callback, ctx);
    }

    const reduceQuery = (query) => Object.keys(query)
        .filter(k => query[k] !== undefined)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(query[k]))
        .join('&');

    const reduceQuery0 = (uri, query) => {
        if (query !== undefined) {
            const queryParams = reduceQuery(query);
            if (queryParams.length !== 0) {
                return uri + '?' + queryParams;
            }
        }
        return uri;
    };

    const baseRequest = async (uri, params, popup, safe = false) => {
        const {api_url = uri, request, method = 'POST', headers, query, json, data, alt, token: use_token = false} = params;
        let body, ha;

        if (data !== undefined) {
            body = data;
        } else if (json !== undefined) {
            body = JSON.stringify(json);
        }

        if (use_token && token.current.token_type !== undefined) {
            ha = {
                Authorization: `${token.current.token_type} ${safe ? token.current.refresh_token : token.current.access_token}`
            }
        }

        const response = await fetch(reduceQuery0(api_url + request, query), {
            method,
            headers: {...headers, ...ha},
            body,
            credentials: 'include'
        });

        if (response.status === 200) {
            return response
        } else if (safe) {
            // все плохо
            return response
        } else if (response.status === 401) {
            const refresh_response = await authRequest({
                token: true,
                request: '/refresh',
                query: {
                    alt: alt ? true : undefined
                }
            }, popup, true)

            if (refresh_response.status === 200) {
                const t0 = await refresh_response.json();

                token.current = t0

                localStorage.setItem('token', JSON.stringify(t0))
                localStorage.setItem('hsdv', t0.hsdv)

                return await baseRequest(uri,
                    {
                        ...params,
                        headers: {
                            ...params.headers,
                            Authorization: `${token.current.token_type} ${t0.access_token}`
                        }
                    },
                    popup,
                    false);
            } else {
                localStorage.removeItem('token');
                token.current = getNullToken()

                if (popup !== undefined) {
                    return await popup();
                }
            }
        }
    }

    const apiRequest = async (params, popup, safe) => baseRequest(url.api, params, popup, safe);
    const authRequest = async (params, popup, safe) => baseRequest(url.auth, params, popup, safe);

    const registerUser = async (params) => {
        const hsdv = localStorage.getItem('hsdv')
        const response = await authRequest({
            request: '/register',
            query: {
                app_id: config?.app_id
                , hsdv: hsdv !== null ? hsdv : undefined
                , ...params
            }
        })

        if (response.status === 200) {
            const t0 = await response.json()

            token.current = t0

            localStorage.setItem('token', JSON.stringify(t0))
            localStorage.setItem('hsdv', t0.hsdv)

            return {...t0}
        } else {
            return {}
        }
    }

    const resetUser = () => {
        localStorage.removeItem('token')
        token.current = getNullToken()
        return {...token.current}
    }

    return (
        <WebConnectContext.Provider
            value={{ws_send, isUserAuthenticated, isRefreshTokenExpired, getNickname, logout, apiRequest, authRequest, registerUser, resetUser}}>
            {children}
        </WebConnectContext.Provider>
    )
}

export default useWebConnect;
