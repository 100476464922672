import React, {lazy, Suspense} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {useDispatch} from "react-redux"
import {YMInitializer} from "react-yandex-metrika"
import {withTracker} from "@sig/probapps-lib"
import WebFont from 'webfontloader'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faEdit, faQrcode, faBars, faSearch, faWindowClose, faFileAlt, faQuestionCircle, faSortAmountUpAlt} from "@fortawesome/pro-light-svg-icons"
import {faInfoCircle} from "@fortawesome/pro-duotone-svg-icons"

import WebConnect from "./modules/api/WebConnect"
import {changeConfig} from "./actions/ConfigAction"

WebFont.load({
    google: {
        families: ['Ubuntu', 'Montserrat', 'Roboto Condensed']
    }
})

library.add(faEdit, faQrcode, faBars, faSearch, faWindowClose, faFileAlt, faQuestionCircle, faSortAmountUpAlt, faInfoCircle)

export const select_lang = [
    {
        title: 'En'
        , code: 'en'
        , flag: 'flag-icon-gb'
        , disable: false
        , active: true
    }
    , {
        title: 'De'
        , code: 'de'
        , flag: 'flag-icon-de'
        , disable: false
        , active: true
    }
    , {
        title: 'Fr'
        , code: 'fr'
        , flag: 'flag-icon-fr'
        , disable: false
        , active: false
    }
    , {
        title: 'It'
        , code: 'it'
        , flag: 'flag-icon-it'
        , disable: false
        , active: false
    }
    , {
        title: 'Ru'
        , code: 'ru'
        , flag: 'flag-icon-ru'
        , disable: false
        , active: true
    }
]

const FairPlay2020 = lazy(() => import('./views/FairPlay2020'))
const Check = lazy(() => import('./views/Check'))

export const FairPlay2020App = () => {
    const dispatch = useDispatch()
    const config = configFetch.read()
    const yandexMetrika = config && config['yandex-metrika']

    dispatch(changeConfig(config))

    return (
        <WebConnect>
            <Router>
                {yandexMetrika && <YMInitializer accounts={yandexMetrika.ids}/>}
                <Switch>
                    <Route exact path="/3k8xZ" component={withTracker(FairPlay2020, '/fp2020', yandexMetrika)}/>
                    <Route exact path="/check/:game_id" component={withTracker(Check, '/check', yandexMetrika)}/>

                    <Route path="/" component={withTracker(() => (<div>^</div>), '/', yandexMetrika)}/>
                </Switch>
            </Router>
        </WebConnect>
    )
}

const wrapPromise = (promise) => {
    let status = "pending";
    let result;
    let suspender = promise.then(
        r => {
            status = "success";
            result = r;
        },
        e => {
            status = "error";
            result = e;
        }
    );
    return {
        read() {
            if (status === "pending") {
                throw suspender;
            } else if (status === "error") {
                throw result;
            } else if (status === "success") {
                return result;
            }
        }
    };
};

const configFetch = wrapPromise(
    fetch('/config.json')
        .then(response => response.json())
);

export const App = () => (
    <Suspense fallback="config loading...">
        <FairPlay2020App/>
    </Suspense>
);
